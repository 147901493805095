import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Form, Field } from 'react-final-form';
import { createForm } from 'final-form';
import { useKey } from 'react-use';
import { useTranslation } from 'react-i18next';

import { BasicField, CheckboxField } from 'components/fields';
import { BasicButton } from 'components/buttons';
import saveIcon from 'assets/icons/ok.svg';
import cancelIcon from 'assets/icons/cross-dark.svg';
import getIsApplyFilterAllowed from 'helpers/getIsApplyFilterAllowed';

import './styles.scss';

const NewMaskFilter = forwardRef(({
  api,
  filterChangedCallback,
  styles,
  onCancel = Function.prototype,
  colDef: {
    field,
    fieldId,
    headerComponentParams: {
      withoutEmpty,
    } = {},
  } = {},
}, ref) => {
  const { t } = useTranslation();

  useKey('Escape', onCancel);

  const isApply = useRef();
  const formRef = useRef();
  const fieldRef = useRef();
  if (!ref.current) {
    const f = createForm({
      onSubmit: (values) => {
        isApply.current = true;
        filterChangedCallback({ values, field, fieldId });

        if (api) {
          api.menuFactory.hidePopup();
        }
      },
    });
    f.pauseValidation();
    formRef.current = f;
  }

  useImperativeHandle(ref, () => ({
    getModel() {
      // eslint-disable-next-line react/no-this-in-sfc
      if (!this.isFilterActive()) {
        return null;
      }

      return {
        values: formRef.current.getState().values,
        type: 'mask',
      };
    },
    setModel(data) {
      isApply.current = true;
      if (data) {
        const { values } = data;
        if (!withoutEmpty && values.value) {
          values.isMask = true;
        }

        formRef.current.initialize(values);
        // todo нужно для таких кейсов, применен именной фильтр к одному атрибуту
        // а потом применяем именой фильтр к другому атрибуты и поля в этом фильтре не заполняются
        setTimeout(() => {
          formRef.current.initialize({});
          formRef.current.initialize(values);
        });
      } else {
        formRef.current.initialize({ value: '' });
      }
    },
    isFilterActive() {
      const { values } = formRef.current.getState();

      if (!values || !isApply.current) {
        return false;
      }

      return !!values.value || values.empty || values.notempty || values.isMask;
    },
    doesFilterPass() {
      return true;
    },
    afterGuiAttached() {
      const { values } = formRef.current.getState();

      if (!values.empty && !values.notempty) {
        if (!withoutEmpty && !values.isMask) {
          setTimeout(() => {
            formRef.current.change('isMask', true);
            fieldRef.current.focus();
          });
        } else {
          fieldRef.current.focus();
        }
      }
    },
  }));

  return (
    <Form
      form={formRef.current}
      keepDirtyOnReinitialize
      // initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        pristine,
        values,
      }) => (
        <form onSubmit={handleSubmit} className="mkit-mask-filter" style={styles}>
          {
            !withoutEmpty && (
              <>
                <Field
                  name="empty"
                  component={CheckboxField}
                  label={t('FILTERS.EMPTY_VALUE')}
                  wrapperStyle={{ marginBottom: 12, marginLeft: 5 }}
                  isDisabled={values.isMask || values.notempty}
                />
                <Field
                  name="notempty"
                  component={CheckboxField}
                  label={t('FILTERS.NOT_EMPTY')}
                  wrapperStyle={{ marginBottom: 8, marginLeft: 5 }}
                  isDisabled={values.isMask || values.empty}
                />
              </>
            )
          }
          {
            withoutEmpty
              ? (
                <Field
                  name="value"
                  component={BasicField}
                  inputRef={fieldRef}
                  placeholder={t('FILTERS.ENTER_MASK')}
                  withoutError
                />
              )
              : (
                <div className="mkit-mask-filter__row">
                  <Field
                    name="isMask"
                    component={CheckboxField}
                    isDisabled={values.notempty || values.empty}
                  />
                  <Field
                    name="value"
                    component={BasicField}
                    inputRef={fieldRef}
                    placeholder={t('FILTERS.ENTER_MASK')}
                    styleInput={{ width: '100%', paddingRight: 12 }}
                    isDisabled={!values.isMask}
                    withoutError
                  />
                </div>
              )
          }
          <div className="mkit-mask-filter__actions-wrapper">
            <BasicButton
              text={t('CONTROLS.CLEAR')}
              type={BasicButton.types.SECONDARY}
              icon={cancelIcon}
              filterType="white"
              onClick={form.reset}
            />
            <BasicButton
              text={t('CONTROLS.APPLY')}
              type={BasicButton.types.ACCENT}
              icon={saveIcon}
              onClick={handleSubmit}
              disabled={pristine || getIsApplyFilterAllowed(values, 'mask')}
            />
          </div>
        </form>
      )}
    />
  );
});

export default NewMaskFilter;
