import { CHANNELS_DICTIONARY } from 'mkit-front-ds/dist/consts';

import endpoints from 'const/endpoints';

export default token => ({
  url: endpoints.getAvailableChannelsUrl(),
  options: {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  },
  transform: ({ resourceList }) => ({
    availableChannelsOptions: (resourceList?.length ? resourceList : [1]).filter(channel => channel !== 5)
      .map(channelKey => ({
        value: channelKey,
        ...CHANNELS_DICTIONARY[channelKey],
      })),
  }),
  update: {
    availableChannelsOptions: (oldValue, newValue) => newValue,
  },
});
