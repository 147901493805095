export default (values, filterType) => {
  switch (filterType) {
    case 'number':
    case 'date':
      if (values.empty || values.notempty) {
        return false;
      }
      if (values.byValue) {
        if ((values.range ? values.fromValue || values.toValue : values.fromValue)) {
          return false;
        }
      }
      return true;
    case 'mask':
      if (values.empty || values.notempty) {
        return false;
      }
      if (values.isMask) {
        if (values.value) {
          return false;
        }
      }
      return true;
    case 'list':
      return !Object.keys(values).some(key => values[key] === true);
    default:
      return false;
  }
};
