import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { createForm } from 'final-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { BasicField, CheckboxField, SwitchField } from 'components/fields';
import { BasicButton } from 'components/buttons';
import saveIcon from 'assets/icons/ok.svg';
import cancelIcon from 'assets/icons/cross-dark.svg';
import getIsApplyFilterAllowed from 'helpers/getIsApplyFilterAllowed';

import './style.scss';

const NumberFilter = forwardRef(({
  styles,
  onSubmit: onExternalSubmit,
  initialValues,
  colDef: {
    field,
    headerComponentParams: {
      withoutEmpty = false,
    } = {},
  } = {},
  filterChangedCallback,
  api,
}, ref) => {
  const paddingLeft = withoutEmpty ? 0 : 37;

  const { t } = useTranslation();

  const isApply = useRef();
  const formRef = useRef();
  if (!ref?.current) {
    const form = createForm({
      onSubmit: (values, actions) => {
        // todo delete after the introduction of new tables
        if (onExternalSubmit) {
          onExternalSubmit(values, actions);
        } else {
          isApply.current = true;
          filterChangedCallback({
            values,
            field,
          });
          api.menuFactory.hidePopup();
        }
      },
    });
    form.pauseValidation();
    formRef.current = form;
  }

  useImperativeHandle(ref, () => ({
    getModel() {
      // eslint-disable-next-line react/no-this-in-sfc
      if (!this.isFilterActive()) {
        return null;
      }

      const { values } = formRef.current.getState();

      return {
        values,
        type: 'number',
      };
    },
    setModel(data) {
      isApply.current = true;
      if (data) {
        const { values } = data;
        formRef.current.initialize(values);
        // todo нужно для таких кейсов, применен именной фильтр к одному атрибуту
        // а потом применяем именой фильтр к другому атрибуты и поля в этом фильтре не заполняются
        setTimeout(() => {
          formRef.current.initialize({});
          formRef.current.initialize(values);
        });
      } else {
        formRef.current.initialize({});
      }
    },
    isFilterActive() {
      const { values } = formRef.current.getState();

      if (!values || !isApply.current) {
        return false;
      }

      return Object.values(values).some(item => item);
    },
    doesFilterPass() {
      return true;
    },
  }));

  return (
    <Form
      form={formRef.current}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form,
        values,
        pristine,
      }) => {
        const numberFilterClass = classNames({
          'mkit-number-filter': true,
          'mkit-number-filter-range': values && values.range,
        });

        return (
          <div className={numberFilterClass} style={styles}>
            {
              !withoutEmpty && (
                <>
                  <Field
                    name="empty"
                    component={CheckboxField}
                    label={t('FILTERS.EMPTY_VALUE')}
                    wrapperStyle={{ marginBottom: 12, marginLeft: 5 }}
                  />
                  <Field
                    name="notempty"
                    component={CheckboxField}
                    label={t('FILTERS.NOT_EMPTY')}
                    wrapperStyle={{ marginBottom: 8, marginLeft: 5 }}
                    isDisabled={values.byValue}
                  />
                </>
              )
            }
            <div className="mkit-number-filter__row">
              {
                !withoutEmpty && (
                  <Field
                    name="byValue"
                    component={CheckboxField}
                    isDisabled={values.notempty}
                    wrapperStyle={{ marginLeft: 5 }}
                  />
                )
              }
              <Field
                name="fromValue"
                component={BasicField}
                styleWrapper={{ width: '100%' }}
                placeholder={values.range ? t('FILTERS.MORE_VALUE') : t('FILTERS.VALUE')}
                isDisabled={values.notempty || (!withoutEmpty && !values.byValue)}
                withoutError
              />
            </div>
            <Field
              name="range"
              component={SwitchField}
              label={t('FILTERS.BY_RANGE')}
              wrapperStyle={{ marginTop: 15, paddingLeft }}
              labelStyle={{ marginRight: 'auto' }}
              isDisabled={!withoutEmpty && (values.notempty || !values.byValue)}
            />
            {
              values?.range && (
                <Field
                  name="toValue"
                  component={BasicField}
                  placeholder={t('FILTERS.LESS_VALUE')}
                  styleInput={{ paddingLeft }}
                  isDisabled={values.notempty || !values.byValue}
                  withoutError
                />
              )
            }
            <div className="mkit-number-filter__actions-wrapper">
              <BasicButton
                text={t('CONTROLS.CLEAR')}
                type={BasicButton.types.SECONDARY}
                icon={cancelIcon}
                filterType="white"
                onClick={form.reset}
              />
              <BasicButton
                text={t('CONTROLS.APPLY')}
                type={BasicButton.types.ACCENT}
                icon={saveIcon}
                onClick={handleSubmit}
                disabled={pristine || getIsApplyFilterAllowed(values, 'number')}
              />
            </div>
          </div>
        );
      }}
    />
  );
});

export default NumberFilter;
