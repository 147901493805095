import React, { useMemo } from 'react';
import { createForm } from 'final-form';
import { Form, Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import {
  BasicField,
  DropdownFieldNew,
  DropdownFieldArray,
  SwitchField,
} from 'components/fields';
import { BasicButton } from 'components/buttons';
import { useDictionariesApi } from 'hooks/api';
import { required } from 'helpers/validators';
import { CHANNELS_DICTIONARY } from 'const';
import disketteIcon from 'assets/icons/diskette-dark.svg';
import crossIcon from 'assets/icons/cross-grey.svg';
import trashIcon from 'assets/icons/trash.svg';

import './style.scss';

const EditScenarioForm = ({
  onSubmit,
  onDelete,
  onCancel,
  initialValues,
  isLoading,
  isFormPristine,
  editAllowed,
  deleteAllowed,
}) => {
  const inputStyle = { maxWidth: '73%' };
  const fieldWrapperStyle = { marginBottom: 15 };
  const stylesBasicField = {
    container: fieldWrapperStyle,
    inputContainer: inputStyle,
  };
  const stylesDropdownField = {
    container: fieldWrapperStyle,
    content: inputStyle,
  };
  const stylesSwitchField = {
    wrapper: fieldWrapperStyle,
    label: { marginRight: 93 },
  };

  const { availableChannelsOptions } = useDictionariesApi();

  const { t } = useTranslation();

  const isEdit = useMemo(() => initialValues && !isEmpty(initialValues), [initialValues]);
  const channelsOptions = useMemo(
    () =>
      availableChannelsOptions.filter(item => item.value !== 7 && item.value !== 8).map(I => ({
        value: I.value,
        ...CHANNELS_DICTIONARY[I.value],
      })),
    [availableChannelsOptions],
  );

  const defaultInitialValues = {
    channel: channelsOptions[0]?.value,
    defaultFailureAction: 2,
    stuckTimeout: 300,
    stuckTimeoutAction: 1,
    responseTimeout: 900,
    responseTimeoutAction: 1,
  };
  const formApi = createForm({
    onSubmit,
    initialValues: initialValues || defaultInitialValues,
    mutators: arrayMutators,
  });

  const conditionInitialValue = { conditionType: 1 };

  const optionsReplyType = [
    {
      label: 'Regexp',
      value: 1,
    },
    {
      label: 'Keyphrase',
      value: 2,
    },
    {
      label: 'Intent',
      value: 3,
    },
  ];
  const optionsActionFailure = [
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.CONTINUE'),
      value: 1,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.END_CHAT'),
      value: 2,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.FAILOVER'),
      value: 3,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.HANDOVER'),
      value: 4,
    },
  ];
  const optionsActionStuckTimeout = [
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.END_CHAT'),
      value: 1,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.GO_TO_STUCK_TIMEOUT'),
      value: 2,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.HANDOVER'),
      value: 3,
    },
  ];
  const optionsActionResponseTimeout = [
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.END_CHAT'),
      value: 1,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.GO_TO_RESPONSE_TIMEOUT'),
      value: 2,
    },
    {
      label: t('SCENARIOS_CONSTRUCTOR.INSTANCES.HANDOVER'),
      value: 3,
    },
  ];

  return (
    <Form
      form={formApi}
      render={
        ({
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} className="edit-scenario-form">
            <FormSpy
              subscription={{ pristine: true }}
              onChange={(props) => {
                isFormPristine.current = props.pristine;
              }}
            />
            <h1 className="edit-scenario-form__header">
              {t('SCENARIOS_CONSTRUCTOR.INSTANCES.SCENARIO_SETTINGS', { value: initialValues && initialValues.name })}
            </h1>
            <div className="edit-scenario-form__content">
              <Field
                name="name"
                placeholder={t('SCENARIOS_CONSTRUCTOR.INSTANCES.SCENARIO_NAME')}
                component={BasicField}
                label={t('INSTANCES.NAME')}
                styles={stylesBasicField}
                validate={value => required(value, t)}
                isDisabled={isEdit && !editAllowed}
              />
              <Field
                name="channel"
                component={DropdownFieldNew}
                label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.SELECT_CHANNEL')}
                placeholder={t('INSTANCES.CHANNEL')}
                options={channelsOptions}
                type="underline"
                styles={stylesDropdownField}
                validate={value => required(value, t)}
                disabled={isEdit}
              />
              <Field
                name="defaultFailureAction"
                component={DropdownFieldNew}
                label={t('SCENARIOS_CONSTRUCTOR.INSTANCES.ON_FAILURE')}
                options={optionsActionFailure}
                type="underline"
                styles={stylesDropdownField}
                validate={value => required(value, t)}
                disabled={isEdit && !editAllowed}
              />
              <Field
                name="stuckTimeout"
                label={`${t('SCENARIOS_CONSTRUCTOR.INSTANCES.STUCK_TIMEOUT')} (${t('PLACEHOLDERS.SEC')})`}
                component={BasicField}
                placeholder={t('SCENARIOS_CONSTRUCTOR.INSTANCES.STUCK_TIMEOUT')}
                styles={stylesBasicField}
                isDisabled={isEdit && !editAllowed}
              />
              <Field
                name="stuckTimeoutAction"
                placeholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.STUCK_TIMEOUT_ACTION')}
                component={DropdownFieldNew}
                label={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.STUCK_TIMEOUT_ACTION')}
                styles={stylesDropdownField}
                options={optionsActionStuckTimeout}
                type="underline"
                disabled={isEdit && !editAllowed}
              />
              <Field
                name="responseTimeout"
                component={BasicField}
                placeholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.RESPONSE_TIMEOUT')}
                label={`${t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.RESPONSE_TIMEOUT')} (${t('PLACEHOLDERS.SEC')})`}
                styles={stylesBasicField}
                isDisabled={isEdit && !editAllowed}
              />
              <Field
                name="responseTimeoutAction"
                placeholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.RESPONSE_TIMEOUT_ACTION')}
                component={DropdownFieldNew}
                label={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.RESPONSE_TIMEOUT_ACTION')}
                styles={stylesDropdownField}
                options={optionsActionResponseTimeout}
                type="underline"
                disabled={isEdit && !editAllowed}
              />
              <Field
                name="isDefault"
                component={SwitchField}
                label={t('INSTANCES.IS_DEFAULT')}
                wrapperStyle={fieldWrapperStyle}
                styles={stylesSwitchField}
                isDisabled={isEdit && !editAllowed}
              />
              <div className="edit-scenario-form__label">
                {t('SCENARIOS_CONSTRUCTOR.INSTANCES.TRIGGER_CONDITIONS')}
              </div>
              <FieldArray
                name="triggerCondition"
                component={DropdownFieldArray}
                buttonText={t('SCENARIOS_CONSTRUCTOR.CONTROLS.ADD_PARAMETER')}
                options={optionsReplyType}
                dropdownPlaceholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.SELECT_CONDITION')}
                dropdownName="conditionType"
                fieldName="condition"
                initialValues={conditionInitialValue}
                disabled={isEdit && !editAllowed}
              />
              <div className="edit-scenario-form__label">
                {t('SCENARIOS_CONSTRUCTOR.INSTANCES.RESET_CONDITIONS')}
              </div>
              <FieldArray
                name="resetCondition"
                component={DropdownFieldArray}
                buttonText={t('SCENARIOS_CONSTRUCTOR.CONTROLS.ADD_PARAMETER')}
                options={optionsReplyType}
                dropdownPlaceholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.SELECT_CONDITION')}
                dropdownName="conditionType"
                fieldName="condition"
                initialValues={conditionInitialValue}
                disabled={isEdit && !editAllowed}
              />
              <div className="edit-scenario-form__label">
                {t('SCENARIOS_CONSTRUCTOR.INSTANCES.STOP_CONDITIONS')}
              </div>
              <FieldArray
                name="stopCondition"
                component={DropdownFieldArray}
                buttonText={t('SCENARIOS_CONSTRUCTOR.CONTROLS.ADD_PARAMETER')}
                options={optionsReplyType}
                dropdownPlaceholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.SELECT_CONDITION')}
                dropdownName="conditionType"
                fieldName="condition"
                initialValues={conditionInitialValue}
                disabled={isEdit && !editAllowed}
              />
              <div className="edit-scenario-form__label">
                {t('SCENARIOS_CONSTRUCTOR.INSTANCES.OPT_OUT_CONDITIONS')}
              </div>
              <FieldArray
                name="optOutCondition"
                component={DropdownFieldArray}
                buttonText={t('SCENARIOS_CONSTRUCTOR.CONTROLS.ADD_PARAMETER')}
                options={optionsReplyType}
                dropdownPlaceholder={t('SCENARIOS_CONSTRUCTOR.PLACEHOLDERS.SELECT_CONDITION')}
                dropdownName="conditionType"
                fieldName="condition"
                initialValues={conditionInitialValue}
                disabled={isEdit && !editAllowed}
              />
            </div>
            <div className="edit-scenario-form__buttons">
              <div className="edit-scenario-form__buttons__group">
                {
                  initialValues
                    ? (
                      <BasicButton
                        text={t('CONTROLS.DELETE')}
                        type={BasicButton.types.WARN}
                        icon={trashIcon}
                        onClick={() => onDelete(initialValues)}
                        filterType="only-white"
                        disabled={isLoading || !deleteAllowed}
                        title={deleteAllowed ? undefined : 'Not enough permissions'}
                      />
                    )
                    : (
                      <BasicButton
                        text={t('CONTROLS.CANCEL')}
                        type={BasicButton.types.CANCEL}
                        icon={crossIcon}
                        onClick={onCancel}
                        disabled={isLoading}
                        isNewStyle
                      />
                    )
                }
              </div>
              <div className="edit-scenario-form__buttons__group">
                {
                  initialValues && (
                    <BasicButton
                      text={t('CONTROLS.CANCEL')}
                      type={BasicButton.types.CANCEL}
                      icon={crossIcon}
                      onClick={onCancel}
                      disabled={isLoading}
                      isNewStyle
                    />
                  )
                }
                <BasicButton
                  text={t('CONTROLS.SAVE')}
                  type={BasicButton.types.ACCENT}
                  icon={disketteIcon}
                  onClick={handleSubmit}
                  style={{ marginLeft: '20px' }}
                  disabled={isEdit && !editAllowed}
                  title={(isEdit && !editAllowed) ? 'Not enough permissions' : undefined}
                  filterType="only-white"
                  isNewStyle
                />
              </div>
            </div>
          </form>
        )
      }
    />
  );
};

export default EditScenarioForm;
