import React, {
  forwardRef,
  useState,
  useMemo,
  useRef,
  useImperativeHandle,
} from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { useKey } from 'react-use';
import { createForm } from 'final-form';
import { useTranslation } from 'react-i18next';

import { BasicButton } from 'components/buttons';
import { CheckboxField, SearchField } from 'components/fields';
import saveIcon from 'assets/icons/ok.svg';
import cancelIcon from 'assets/icons/cross-dark.svg';
import magnifyingGlassIcon from 'assets/icons/magnifying-glass.svg';
import getIsApplyFilterAllowed from 'helpers/getIsApplyFilterAllowed';

import './style.scss';

const ListFilterForm = forwardRef(({
  // initialValues,
  onSubmit: onExternalSubmit,
  withFilter = false,
  onCancel = Function.prototype,
  colDef: {
    field,
    headerComponentParams,
  } = {},
  withSearch = headerComponentParams?.withSearch,
  withoutEmpty = headerComponentParams?.withoutEmpty,
  items = headerComponentParams?.items,
  filterChangedCallback,
  api,
}, ref) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  useKey('Escape', onCancel);

  const foundItems = useMemo(
    () => {
      if (!searchValue.trim()) {
        return items;
      }

      const desiredValue = searchValue.trim().toLowerCase();

      return items.filter(item => item.title.toLowerCase().includes(desiredValue));
    },
    [searchValue, items],
  );
  const wrapperStyle = {
    marginBottom: 12,
  };

  const isApply = useRef();
  const formRef = useRef();
  if (!ref?.current) {
    const form = createForm({
      onSubmit: (values, actions) => {
        // todo убрать когда уберу возмодность создавать фильтры '2323'
        let processedValues = values;
        if (withFilter) {
          processedValues = Object.keys(values).reduce((acc, key) => {
            acc[key.replace('_', '')] = values[key];
            return acc;
          }, {});
        }

        // todo delete after the introduction of new tables
        if (onExternalSubmit) {
          onExternalSubmit(processedValues, actions);
        } else {
          isApply.current = true;
          filterChangedCallback({
            values: processedValues,
            field,
          });
          api.menuFactory.hidePopup();
        }
      },
    });
    form.pauseValidation();
    formRef.current = form;
  }

  useImperativeHandle(ref, () => ({
    getModel() {
      // eslint-disable-next-line react/no-this-in-sfc
      if (!this.isFilterActive()) {
        return null;
      }

      let { values } = formRef.current.getState();

      values = Object.keys(values).reduce((acc, key) => {
        acc[key.replace('_', '')] = values[key];
        return acc;
      }, {});

      return {
        values,
        type: 'list',
      };
    },
    setModel(data) {
      isApply.current = true;
      if (data) {
        const { values } = data;
        const nextValues = Object.keys(values || {}).reduce((acc, key) => {
          if (key !== 'empty' && key !== 'notempty') {
            acc[`_${key}`] = values[key];
          } else {
            acc[key] = values[key];
          }
          return acc;
        }, {});
        formRef.current.initialize(nextValues);

        // todo нужно для таких кейсов, применен именной фильтр к одному атрибуту
        // а потом применяем именой фильтр к другому атрибуты и поля в этом фильтре не заполняются
        setTimeout(() => {
          formRef.current.initialize({});
          formRef.current.initialize(nextValues);
        });
      } else {
        formRef.current.initialize({});
      }
    },
    isFilterActive() {
      const { values } = formRef.current.getState();

      if (!values || !isApply.current) {
        return false;
      }

      return Object.values(values).some(item => item);
    },
    doesFilterPass() {
      return true;
    },
  }));

  return (
    <Form
      keepDirtyOnReinitialize
      form={formRef.current}
      // initialValues={initialValues}
      render={
        ({
          handleSubmit,
          form: {
            change,
            reset,
          },
          pristine,
          values,
        }) => (
          <div className="mkit-list-filter-form">
            {
              !withoutEmpty && (
                <>
                  <Field
                    name="empty"
                    component={CheckboxField}
                    label={t('FILTERS.EMPTY_VALUE')}
                    wrapperStyle={wrapperStyle}
                  />
                  <Field
                    name="notempty"
                    component={CheckboxField}
                    label={t('FILTERS.NOT_EMPTY')}
                    wrapperStyle={wrapperStyle}
                  />
                </>
              )
            }
            <FormSpy
              subscription={{ values: true }}
              onChange={(props) => {
                if (props.values.notempty && !values.notempty) {
                  items.forEach(item => change(`_${item.id}`, true));
                } else if (props.values.notempty !== values.notempty) {
                  items.forEach(item => change(`_${item.id}`, false));
                }
              }}
            />
            {
              withSearch && (
                <SearchField
                  input={{
                    value: searchValue,
                    onChange: setSearchValue,
                    name: 'search',
                  }}
                  icon={magnifyingGlassIcon}
                  styleWrapper={wrapperStyle}
                  placeholder={t('PLACEHOLDERS.SEARCH_SOMETHING')}
                />
              )
            }
            <div className="mkit-list-filter-form__fields-wrapper">
              {
                foundItems.map(item => (
                  <Field
                    key={item.id}
                    name={`_${item.id}`}
                    component={CheckboxField}
                    label={item.title}
                    icon={item.icon}
                    wrapperStyle={{ margin: '6px 0' }}
                  />
                ))
              }
            </div>
            <div className="mkit-list-filter-form__buttons">
              <BasicButton
                text={t('CONTROLS.CLEAR')}
                type={BasicButton.types.SECONDARY}
                icon={cancelIcon}
                filterType="white"
                onClick={reset}
                disabled={pristine}
              />
              <BasicButton
                text={t('CONTROLS.APPLY')}
                type={BasicButton.types.ACCENT}
                icon={saveIcon}
                onClick={handleSubmit}
                disabled={pristine || getIsApplyFilterAllowed(values, 'list')}
              />
            </div>
          </div>
        )
      }
    />
  );
});

export default ListFilterForm;
